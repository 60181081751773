<template>
    <div class="demo">
        <div class="title">简书 - React实战
            <li>使用 styled-components </li>
            <li>使用 react-router-dom</li>
            <li>使用 combineReducers</li>
            blog地址：<a href="https://blog.csdn.net/daddykei/article/details/119191099">csdn</a>
            git地址：<a href="https://gitee.com/thinkerwing/jianshu">jianshu gitee</a>
            <br/>
            <div style="margin-top: 10px;"></div>
            掘金： 代码不停, 掘金不止<a href="https://gitee.com/thinkerwing/juejin">juejin gitee</a>
        </div>
    </div>
</template>

<style scoped>
    .demo {
        display: flex;
        height: 100%;
        width: 100%;
        color: #333;
        text-shadow: none;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 16px;
        text-align: left;
        margin: 10%;
    }
</style>
<script>
    export default {
        data() {
            return {
            }
        },
        created() {
        }
    }
</script>